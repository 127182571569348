import React from 'react';
import './Cookieconsent.css';


class Cookieconsent extends React.Component{

    constructor(){
        super()

        this.state = {
            showBanner: true,
            showControl: false,
            debug: false
        }

        this.accept = this.accept.bind(this)
        this.deny = this.deny.bind(this)
        this.control = this.control.bind(this)

        //transfor to "window"-Object
        //call like <button onclick="window.cookieconsentControl()">Click me</button>
        window.cookieconsentControl = this.control
    }

    componentDidMount(){
        this.checkHistory()
    }

    checkHistory(){

        //check if local storage exist
        let localStorageValue = this.getLocalStorage(2) //decision: allow or disallow
        if(typeof(localStorageValue.decision) != "undefined"){
            //ls exist

            if(this.state.debug === true){
                console.log("local storage with decision '" + localStorageValue.decision + "' exist")
            }

            //check if sesseion consent cookie exist
            if(document.cookie.indexOf("cookieconsent") >= 0){
                //cookie (from current session) exist
                let cookieValue = this.getCookieValue("cookieconsent") //allow or diallow

                if(this.state.debug === true){
                    console.log("cookie with decision '" + cookieValue + "' exist")
                }

                //disable banner for session
                this.setState({
                    showBanner: false,
                })
            }else{
                //cookie not exist

                if(this.state.debug === true){
                    console.log("cookie not exist")
                }

                if(localStorageValue.decision === "allow"){
                    //set cookie because ls was allow
                    this.accept(true)
                }else{
                    if(this.state.debug === true){
                        console.log("new query because cookie not exist and local storage was dissalow")
                    }
                }
            }
 
        }else{
            //ls not exist
            //new query because we cant trust only the cookie
            if(this.state.debug === true){
                console.log("new query because local sotrage not exist and we cant trust the cookie")
            }
        }


    }

    getCookieValue(name) {
	    var v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
	    return v ? v[2] : null;
	}

    accept(onlyCookie){

        if(this.state.debug === true){
            console.log("(re)set cookie with decision 'allow'")
        }

        //set cookie for a long time
        document.cookie = "cookieconsent=allow; expires=Thu, 31 Dec 2199 23:59:59 UTC; path=/";
  
        if(onlyCookie === false){
            //set ls
            this.setLocalStorage("allow")
            
            //push for GTM
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: "allowCookie"
            });
        }

        //disable banner + control
        this.setState({
            showBanner: false,
            showControl: false
        })
    }

    deny(){
        if(this.state.debug === true){
            console.log("(re)set cookie with decision 'dissalow'")
        }

        //set cookie (only for session)
        document.cookie = "cookieconsent=disallow; path=/";

        //set ls
        this.setLocalStorage("disallow")

        //disable banner + control
        this.setState({
            showBanner: false,
            showControl: false
        })
    }

    control(){
        //disable banner + show control
        this.setState({
            showBanner: false,
            showControl: true
        })
    }

    setLocalStorage(decision = "allow"){
        let ls =  []

        //check if ls exist
        if(window.localStorage.getItem('cookieconsent') !== null){
            if(this.state.debug === true){
                console.log("old local storage exist")
            }
            //read array and append if different
            ls = JSON.parse(window.localStorage.getItem('cookieconsent'));

        }

        let timestamp = new Date().toISOString()
        ls.push({"timestamp": timestamp, "decision": decision})

        //write (back) to ls
        window.localStorage.setItem('cookieconsent', JSON.stringify(ls))
        
        if(this.state.debug === true){
            console.log("(re)set local storage with new decision '" + decision + "'")
        }
    }

    //1 == complete, 2 == onlyLast value
    getLocalStorage(complete = 1){
        let ls =  []

        //check if ls exist
        if(window.localStorage.getItem('cookieconsent') !== null){
            
            if(this.state.debug === true){
                console.log("local storage exist")
            }
            
            //read array and append
            ls = JSON.parse(window.localStorage.getItem('cookieconsent'));

            if(complete === 1){
                //return complete array
                return ls
            }else{
                //return only last value
                let last = ls.length -1
                return ls[last]
            }
        }

        //ls not exist
        if(this.state.debug === true){
            console.log("local storage not exist")
        }

        return -1;
    }



    render(){

        let historyOutput = "Sie haben bisher noch keine Entscheidung zu Cookies getroffen" //default
        if(this.state.showControl === true){
            let history = this.getLocalStorage();

            if(history !== -1){
                historyOutput = history.map((value, index) => {
                    let ts = new Date(value.timestamp)
                    return <li key={index}>On {ts.toUTCString()} decided to {value.decision} Cookies</li>
                })
            }
        }

        return (
            <div>
                {this.state.showBanner ?
                    <div className="ccContainer">
                        <div className="ccWraperBanner">
                            <p className="ccContent">
                                Wir nutzen Cookies, um Ihre Erfahrung mit unserer Seite zu verbessern. Manche Cookies sind notwendig, damit unsere Internetseite funktioniert. Mit anderen Cookies können wir beispielsweise die Zugriffe auf unsere Webseite analysieren und auswerten. Sie haben die volle Kontrolle, denn: Sie können Ihre <span className="ccLink" onClick={this.control}>
                                Cookie-Einstellungen jederzeit ändern.</span>
                            </p>
                            <div className="ccWrapperButton">
                                <div className="ccButton" onClick={(e) => this.accept(false)}>
                                    ✓ Cookies akzeptieren
                                </div>
                            </div>
                        </div>
                    </div>
                :
                    null
                }

                {this.state.showControl ?
                    <div className="ccOverlay">
                        <div className="ccPopup">
                            <h1>Sie haben die volle Kontrolle:</h1>
                            <p>Wir nutzen Cookies, um Ihre Erfahrung mit unserer Seite zu verbessern. Manche Cookies sind notwendig, damit unsere Internetseite funktioniert. Mit anderen Cookies können wir beispielsweise die Zugriffe auf unsere Webseite analysieren und auswerten. Sie können Ihre Cookie-Einstellungen hier jederzeit ändern. Mehr Informationen finden Sie in unserer Datenschutzerklärung.</p>
                            <br /><br />
                            <div className="ccWrapperButton">
                                <div className="ccButton" onClick={(e) => this.accept(false)}>
                                    Cookies akzeptieren
                                </div>
                            </div>

                            <div className="ccWrapperButton">
                                <div className="ccButton" onClick={this.deny}>
                                    Cookies verweigern
                                </div>
                            </div>
                            <br />
                            <div className="ccDecisions">
                                <h2>Ihre bisherigen Entscheidungen:</h2>
                                <ul>
                                    {historyOutput}
                                </ul>
                            </div>
                        </div>
                    </div>
                
                :
                    null
                }

            </div>
        )
    }
}

export default Cookieconsent

